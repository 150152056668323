<script>
import FavoritesPreview from '@/components/bibliotheque/favoritesPreview.vue'
// import { debounce } from 'lodash-es'

export default {
  name: 'GroupesWidget',
  components: {
    FavoritesPreview
  },
  data () {
    return {
      page: 1,
      total: this.documents ? this.documents.length : 0,
      qtyPerPage: 5,
      searchDocs: null
    }
  },
  watch: {
    // 'this.$store.state.User.favorites' () {
    //   this.total = this.$store.state.User.favorites.length
    // }
  },
  computed: {
    paginatedList () {
      if (this.documents && this.documents.length > 0) {
        if (this.documents.length > this.qtyPerPage) {
          return this.documents.slice(this.qtyPerPage * (this.page - 1), this.qtyPerPage + (this.qtyPerPage * (this.page - 1)))
        } else {
          return this.documents
        }
      } else {
        return []
      }
    },
    longueur () {
      if (this.documents && this.documents.length) {
        return Math.ceil(this.documents.length / this.qtyPerPage)
      } else {
        return 0
      }
    },
    documents () {
      return this.$store.state.User.favoris.filter(e => e.favori || (e.meta && e.meta.includes('glossaire_graph')))
    }
  },
  methods: {
    // updateSearch: debounce(function updateSearch (val) {
    //   // à faire
    //   if (this.searchQ !== this.searchDocs) {
    //     this.searchQ = this.searchDocs
    //     this.page = 1
    //     this.loading = true
    //   }
    // }, 200, { trailing: true })
  }
}
</script>

<template lang='pug'>
.eleves-widget-index.widget
  v-list.light-list(rounded)
    template(v-if='documents && documents.length > 0')
      div.search-options(v-if='documents && documents.length > qtyPerPage')
        v-text-field(v-model='searchDocs', @keydown='updateSearch', solo, rounded, light, hide-details, dense, placeholder='Recherche à venir', disabled)
      template(v-for='(document, index) in paginatedList')
        template(v-if='index < qtyPerPage')
          favorites-preview(:key='index', :document='document')
          v-divider(v-if='index < paginatedList.length - 1')
    template(v-else)
      div.pa4(style='line-height: 1.15;')
        template
          div.b Vous n'avez aucun cahier numérique en favoris.
          div.f6.mt3 Pour ajouter de nouveaux cahiers pour avoir un accès rapide, dirigez-vous vers la bibliothèque numérique.
        v-btn.mt3(block, rounded, color='primary', to='/bibliotheque', depressed) Voir ma bibliothèque numérique
    .search-options(v-if='documents && documents.length > qtyPerPage')
      v-pagination.pagination-wrapper(v-model='page', :value='page', :length='longueur', circle, color='primary', :total-visible="7")
</template>
<style lang='sass'>
  // @import 'src/styles/components/widgets/_groupesWidget'
</style>

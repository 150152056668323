<script>
import BoutiqueFavorite from '@/mixins/boutiqueFavorite.js'
import TextPattern from '@/components/shared/textPattern.vue'
export default {
  name: 'FavoritesPreview',
  mixins: [BoutiqueFavorite],
  props: {
    document: { type: Object, required: true }
  },
  components: {
    TextPattern
  },
  data () {
    return {
      loading: true,
      boutiqueItem: null,
      showDescription: false
    }
  },
  mounted () {
    if (this.document.meta) {
      this.loading = false
    }
  },
  computed: {
    couverture () {
      if (this.produit && this.produit.image && this.produit.image.medium_image) {
        return { couverture: 'https://plus.dokoma.com' + this.produit.image.medium_image, thumbnail: 'https://plus.dokoma.com' + this.produit.image.thumbnail }
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const couv = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-couverture'))
        if (couv && couv.thumbnail) {
          return { couverture: couv.large_image, thumbnail: couv.thumbnail }
        } else {
          return null
        }
      }
      return null
    },
    picto () {
      if (this.produit && this.produit.image_picto && this.produit.image_picto.url_to_image) {
        return this.produit.image_picto.url_to_image.includes('svg') ? this.produit.image_picto.url_to_image : 'https://plus.dokoma.com' + this.produit.image_picto.url_to_image
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const picto = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-pictogramme'))
        if (picto) {
          return picto.thumbnail
        }
      }
      return null
    },
    queryLink () {
      if (this.produit) {
        if (this.produit.__typename === 'BoutiqueItem') {
          return { document: this.produit.slug, nuage: true }
        } else {
          return { document: this.produit.slug }
        }
      } else {
        return null
      }
    },
    produit () {
      if (this.document.favori) {
        return this.boutiqueItem
      } else {
        // console.log('PAS BOUTIQUE', this.document)
        const parsedVal = JSON.parse(this.document.meta)
        return this.$store.state.Bibliotheque.items.find(e => e.id === parsedVal.data.id)
      }
    }
  },
  apollo: {
    boutiqueItem: {
      query: require('@/graphql/queries/v2/boutiqueItem.gql'),
      variables () {
        return {
          id: this.document.favori.id
        }
      },
      update (data) {
        // console.log('BOUTIQUEITEM', data)
        this.loading = false
        return data.boutiqueItem
      },
      skip () {
        return !this.document.favori
      }
    }
  }
}
</script>

<template lang='pug'>
div.devoir-preview()
  template(v-if='loading')
    v-list-item.eleve-card__main-content(depressed, flat)
      v-skeleton-loader.w-100(type="list-item-avatar-three-line")
  template(v-else)
    v-list-item
      v-list-item-avatar(size='30', color='transparent')
        img(v-if='picto', :src='picto', width='20')
        font-awesome-icon(v-else, :icon="['fad', 'book']", style='font-size: 18px')

      v-list-item-content(v-if='produit')
        v-list-item-title {{produit.titre ? produit.titre : 'Document sans titre'}}
        v-list-item-subtitle.flex.items-center.mt1.flex-wrap(v-if='(produit.niveau || produit.matiere)')
          v-chip.mr1.mt1(v-if='devoir.finAt', x-small, style='padding: 2px 5px', outlined, :color='$store.state.Preferences.darkMode ? "dark" : "primary lighten-4"', text-color='dark')
            font-awesome-icon.mr2(:icon="['fad', 'calendar-alt']")
            span Échéance le {{ new Date(devoir.finAt).toLocaleDateString('fr-CA', { month: 'long', day: 'numeric' }) }}

      div.actions__wrapper
        //- v-menu(offset-y)
          template(v-slot:activator='{ on }')
            v-btn.mr2(icon,small, @click.prevent.stop='', v-on='on')
              font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
          v-card.pv2
            v-list(dense, nav)
              v-list-item(@click='', disabled)
                font-awesome-icon.f6.mr2(:icon="['fad', 'hourglass-end']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Marquer comme terminé (À venir)
        v-btn(fab, depressed, color='secondary', small, :to="{ name: 'bibliotheque', query: queryLink }")
          font-awesome-icon(:icon="['far', 'arrow-right']")
</template>
<style lang='sass' scoped>
</style>

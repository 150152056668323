<script>
import EventCard from '@/components/calendar/eventCard.vue'

export default {
  name: 'calendar',
  components: {
    EventCard
  },
  data () {
    return {
      focus: '',
      type: 'week',
      typeToLabel: {
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        '4day': '4 Jours'
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      semaine: null,
      dateDeDebut: null,
      dateDeFin: null,
      pageEvents: 1,
      evenementsSemaineCourante: null,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party']
    }
  },
  apollo: {
    semaine: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        return {
          filtre: {
            debut: this.dateDeDebut.toJSON(),
            fin: this.dateDeFin.toJSON()
          },
          batch: true,
          page: 1
        }
      },
      update (data) {
        if (this.pageEvents === 1) {
          this.$nextTick(() => {
            this.fetchMoreCalendrier()
            this.addEvents(data.calendrierCalendrierElements.resultat, 'devoir')
          })
        }
        return data.calendrierCalendrierElements
      },
      fetchPolicy: 'network-only',
      skip () {
        return !this.dateDeFin || !this.dateDeDebut
      }
    }
  },
  mounted () {
    // FORMATAGE DATE POUR SERVEUR
    this.dateDeDebut = new Date(this.$refs.calendar.lastStart.date + 'T00:00:00')
    this.dateDeFin = new Date(this.$refs.calendar.lastEnd.date + 'T00:00:00')
    this.$refs.calendar.checkChange()
  },
  computed: {
    calendarView () {
      if (this.$store.state.App.windowSize.width < 800) {
        return 'day'
      } else {
        return 'week'
      }
    }
  },
  methods: {
    addEvents (events, type) {
      // ON IRA CHOPÉ LA COULEUR DANS LA META
      events.forEach((el) => {
        // const dateDeb = new Date(el.debutAt)
        const dateFin = el.finAt ? new Date(el.finAt) : null
        const ind = this.events.findIndex(e => e.batch === el.batch)
        if (ind >= 0) {
          this.events[ind].data.push(el)
        } else {
          this.events.push({
            name: el.titre,
            // start: dateDeb.getUTCFullYear() + '-' + (dateDeb.getUTCMonth() + 1) + '-' + dateDeb.getUTCDate(),
            start: dateFin ? dateFin.getUTCFullYear() + '-' + (dateFin.getUTCMonth() + 1) + '-' + dateFin.getUTCDate() : null,
            end: dateFin ? dateFin.getUTCFullYear() + '-' + (dateFin.getUTCMonth() + 1) + '-' + dateFin.getUTCDate() : null,
            color: type === 'devoir' ? 'green' : 'blue',
            timed: false,
            batch: el.batch,
            calendrier: el.calendrier,
            type,
            data: [el]
          })
        }
      })
      // events.forEach((el) => {
      //   if (el.finAt || el.debutAt) {
      //     const dateEnd = el.finAt ? el.finAt.split('T')[0] : el.debutAt.split('T')[0]
      //     // const dateStart = el.debutAt ? el.debutAt.split('T')[0] : el.finAt.split('T')[0]
      //     this.events.push({
      //       name: el.titre,
      //       start: dateEnd,
      //       end: dateEnd,
      //       color: 'green',
      //       timed: false,
      //       data: el
      //     })
      //   }
      // })
    },
    async fetchMoreCalendrier () {
      if (this.semaine.total > this.semaine.resultat.length) {
        this.pageEvents++
        await this.$apollo.queries.semaine.fetchMore({
          variables: {
            page: this.pageEvents
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newRes = fetchMoreResult.calendrierCalendrierElements.resultat
            this.addEvents(fetchMoreResult.calendrierCalendrierElements.resultat)
            return {
              calendrierCalendrierElements: {
                __typename: previousResult.calendrierCalendrierElements.__typename,
                resultat: [...previousResult.calendrierCalendrierElements.resultat, ...newRes],
                total: previousResult.calendrierCalendrierElements.total
              }
            }
          }
        })
        this.fetchMoreCalendrier()
      }
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      this.dateDeFin = new Date(end.date + 'T00:00:00')
      this.dateDeDebut = new Date(start.date + 'T00:00:00')
      this.pageEvents = 1
      this.events = []
    }
  }
}
</script>

<template lang='pug'>
.calendar-widget__index
  v-sheet.calendar__wrapper()
    v-toolbar(flat, color='secondary', dark)
      v-btn.mr-4(color='primary', @click='setToday', small, rounded)
        | Aujourd'hui
      v-btn.mr-4(color='primary', small, to='/calendrier', rounded)
        | Calendrier complet
      v-spacer
      v-btn(fab, depressed, @click='prev', outlined)
        v-icon
          | mdi-chevron-left
      v-toolbar-title.ttc.mh3(v-if='$refs.calendar')
        | {{ $refs.calendar.title }}
      v-btn(fab, depressed, @click='next', outlined)
        v-icon
          | mdi-chevron-right
    v-calendar(
      ref='calendar'
      v-model='focus'
      color='primary'
      :events='events'
      :event-color='getEventColor'
      :type='calendarView'
      locale='fr-CA'
      @click:event='showEvent'
      @change='updateRange'
      short-weekdays,
      short-months,
      short-intervals,
      interval-count='0',
    )
    v-menu(v-model='selectedOpen', :close-on-content-click='false', :activator='selectedElement', offset-x)
      event-card(:selectedEvent='selectedEvent', @close='selectedOpen = false')
</template>
<style lang='sass'>
  @import 'src/styles/components/widgets/_calendarWidget'

</style>

export default {
  computed: {
    customWeatherIcon () {
      if (this.$store.state.Meteo.meteo.weather[0].icon.includes('01')) {
        // Clear sky
        return { type: 'sun', color: '#ffa603' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('02')) {
        // few clouds
        return { type: 'cloud-sun', color: '#b9bcbf' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('03')) {
        // scattered-clouds
        return { type: 'cloud', color: '#b9bcbf' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('04')) {
        // broken clouds
        return { type: 'clouds', color: '#545a6d' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('09')) {
        // shower-rain
        return { type: 'cloud-showers-heavy', color: '#081646' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('10')) {
        // rain
        return { type: 'cloud-showers', color: '#545a6d' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('11')) {
        // thunderstorm
        return { type: 'thunderstorm', color: '#ffd55f' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('13')) {
        // snow
        return { type: 'snowflakes', color: '#afdeff' }
      } else if (this.$store.state.Meteo.meteo.weather[0].icon.includes('50')) {
        // mist
        return { type: 'fog', color: '#c5bbbb' }
      } else {
        // Fallback
        return { type: 'windsock', color: '#ffc390' }
      }
      // return { type: 'sun', color: '#ffc390' }
    }
  }
}

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
// import { debounce } from 'lodash-es'

export default {
  name: 'colorMenu',
  components: {
    VSwatches
  },
  data () {
    return {
      hover: false,
      menuColor: false,
      tab: null,
      items: [
        'Couleur principale', "Couleur d'accent"
      ]
    }
  },
  computed: {
    darkMode () {
      return this.$store.state.Preferences.darkMode
    },
    themeColor: {
      get () { return this.$store.state.Preferences.themeColor },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'themeColor', data: val })
      }
    },
    themeColorAccent: {
      get () { return this.$store.state.Preferences.themeColorAccent },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'themeColorAccent', data: val })
      }
    },
    themeColorDark: {
      get () { return this.$store.state.Preferences.themeColorDark },
      set (val) {
        this.$store.dispatch('Preferences/setPreferences', { name: 'themeColorDark', data: val })
      }
    }
  },
  watch: {
  },
  mounted () {

  },
  methods: {
    resetColors () {
      if (this.$vuetify.theme.dark) {
        this.themeColor = '#5465ff'
      } else {
        this.themeColor = '#5465ff'
        this.themeColorAccent = '#e2fdff'
      }
    }
  }
}
</script>

<template lang='pug'>

  v-menu(v-model='menuColor', :close-on-content-click='false', :nudge-width='200', offset-x, left, content-class='color-menu')
    template(v-slot:activator='{ on }')
      v-btn.mr2(
        fab, large, depressed
        v-on='on'
        color='secondary'
        @mouseover='hover = true'
        @mouseleave='hover = false'
        v-tooltip.bottom="{ content: 'Changer les couleurs' , offset:'5px'}"
      )
        font-awesome-icon.f3(:icon="['fad', 'palette']", :class="{ 'animated swing infinite': hover }")
    v-card(max-width='400', :color='$store.state.Preferences.darkMode ? "accent" : "light"')
      div.tc.pa2.secondary.white--text {{$store.state.Preferences.darkMode ? "Couleur d'accent" : 'Modifier les couleurs'}}
      template(v-if='$store.state.Preferences.darkMode')
        v-card.swatch-wrapper(flat, color='transparent')
          v-swatches(v-model='themeColorDark', :swatches='$store.state.App.colorLibrary.primary', inline, shapes='circles')
      template(v-else)
        v-tabs(light, v-model='tab', background-color='lightgrey', grow)
          v-tab(v-for='item in items', :key='item')
            span.ttn(style='letter-spacing: 0;') {{ item }}
        v-tabs-items(light, v-model='tab')
          v-tab-item(key='1')
            v-card.swatch-wrapper(flat, color='transparent')
              v-swatches(v-model='themeColorAccent', :swatches='$store.state.App.colorLibrary.accent', inline, shapes='circles')
          v-tab-item(key='2')
            v-card.swatch-wrapper(flat, color='transparent')
              v-swatches(v-model='themeColor', :swatches='$store.state.App.colorLibrary.primary', inline, shapes='circles')
      v-card-actions
        v-spacer
        v-btn(text, @click='resetColors()') Réinitialiser
        v-btn(color='primary', text, @click='menuColor = false') Fermer
</template>
<style lang='sass'>
  .color-menu
    border-radius: 10px 10px 0 0
    .v-tabs
      width: unset
      overflow: hidden
      margin: 0
      .v-tabs-slider-wrapper
        height: 2px !important
        .v-tabs-slider
          color: var(--v-secondary-base)
      .v-tab
        border: solid 2px var(--v-accent-base)
        background-color: var(--v-accent-base)
        &:last-of-type
          border: solid 2px var(--v-primary-base)
          background-color: var(--v-primary-base)
          span
            color: white
      .v-tab--active
        border: solid 2px var(--v-secondary-base) !important
        color: var(--v-secondary-base)
    .v-tabs-items
      margin: 0
      box-sizing: border-box
      border: solid 1px var(--v-secondary-base)
    .swatch-wrapper
      padding: 20px
      .vue-swatches__container
        background-color: transparent !important
      .vue-swatches__wrapper
        display: flex
        justify-content: center
        align-items: center
        flex-wrap: wrap
        .vue-swatches__swatch
          margin: 5px !important
</style>

<script>
import Lottie from '@/components/shared/vueLottie'
export default {
  name: 'InfoNode',
  components: {
    Lottie
  },
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    title: { type: String, required: true }
  },
  data () {
    return {
      loading: false
    }
  },
  apollo: {
    expression: {
      query: require('@/graphql/queries/bot/expression.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.id
        }
      },
      fetchPolicy: 'network-only',
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  }
}
</script>

<template lang="pug">
    .info-node.secondary--text
      template(v-if='loading')
        .content__wrapper
          v-progress-circular(indeterminate)
      template(v-else-if='expression')
        v-carousel(height='300', hide-delimiters, :show-arrows='(expression.images && expression.images.length > 1)', v-if='(expression.images && expression.images.length) || (expression.lotties && expression.lotties.length)')
          v-carousel-item(v-if='(expression.lotties && expression.lotties.length > 0)')
            lottie.lottie-animation(:options='{ autoplay: true, loop: true, animationData: JSON.parse(expression.lotties[0].lottie.json) }')
          v-carousel-item(v-if='(expression.images && expression.images.length > 0)', v-for='(image, i) in expression.images', :key='i + 1', :src='"https://plus.dokoma.com" + image.image', :lazy-src='"https://plus.dokoma.com" + image.image', contain)
        .content__wrapper
          div.section-title(v-if='title' v-html='title')
          div.description(v-if='expression.description' v-html='expression.description')
</template>

<style lang='sass'>
.info-node
  .description
    margin-top: 20px
    strong
      font-weight: $bold
    em, i
      font-style: italic
    ul, ol
      margin-bottom: 10px

    ul
      list-style-type: disc
    ol
      list-style-type: decimal
    h1, h2, h3, h4, h5, h6
      font-family: $display-font
      font-weight: $bold
    h1
      font-size: 30px
    h2
      font-size: 26px
    h3
      font-size: 22px
    h4
      font-size: 20px

    h5
      font-size: 18px
      text-transform: uppercase
      font-weight: normal
    h6
      font-size: 15px
      text-transform: uppercase
      font-weight: normal
</style>

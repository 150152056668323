<script>
export default {
  name: 'EvaluationWidget'
}
</script>

<template lang='pug'>
.evaluation-widget-index.widget
  .widget__secondary-title Exercices et évaluations en cours
</template>
<style lang='scss'>

</style>

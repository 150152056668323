<script>
import meteoIcons from '@/mixins/meteoIcons'

export default {
  name: 'MeteoWidget',
  mixins: [meteoIcons],
  computed: {
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    }
  }
}
</script>

<template lang='pug'>
.meteo-mini-widget
  template(v-if='$store.state.Meteo.meteoLoading')
    v-skeleton-loader(
      type='list-item-avatar-two-line'
      width='200'
    )
  .meteo__wrapper.animated.fadeIn(v-else-if='$store.state.Meteo.meteo')
    v-avatar.mr2(size='44', color='white', v-tooltip.bottom="{ content: $store.state.Meteo.meteo.weather[0].description , offset:'5px'}")
      font-awesome-icon.f3(:icon="['fad', customWeatherIcon.type]", :color='customWeatherIcon.color')

    div.meteo-description
      .meteo-value.f3.b {{Math.round($store.state.Meteo.meteo.main.temp)}}°C
      //- div {{$store.state.Meteo.meteo.weather[0].description}}
      div.meteo__subtitles.f6
        span {{$store.state.Meteo.meteo.name}}, {{$store.state.Meteo.meteo.sys.country}}
  .meteo__wrapper.animated.fadeIn(v-else)
    v-avatar.mr2(color='white')
      font-awesome-icon.f3(:icon="['fad', 'sun-cloud']", color='grey')

    div.meteo-description
      //- .meteo-value.f3.b N/D
      div.meteo__subtitles.f6
        div.b Météo non disponible
        div.f7 Veuillez autoriser la géolocalisation
</template>
<style lang='sass'>
  .meteo-mini-widget
    .meteo__wrapper
      display: flex
      align-items: center
      .meteo-description
        display: flex
        flex-direction: column
        line-height: 1.15
  .v-skeleton-loader
    .v-skeleton-loader__list-item-avatar-two-line
      background: transparent !important
      height: 46px
      padding: 0
</style>

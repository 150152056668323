<script>
import InfoNode from '@/components/chatbot/infoNode'
export default {
  name: 'ChatBot',
  components: {
    InfoNode
  },
  data () {
    return {
      nextPage: 'next'
    }
  },
  computed: {
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    },
    baseSelectedNode () {
      return this.$store.state.Chatbot.baseNodeSelect
    },
    currentSelectedNode () {
      return this.$store.state.Chatbot.nodeSelect
    },
    histo () {
      return this.$store.state.Chatbot.navigation
    },
    customAnimationEnter () {
      if (this.nextPage === 'last') {
        let animation = 'animated fadeInLeft faster'
        return animation
      } else if (this.nextPage === 'next') {
        let animation = 'animated fadeInRight faster'
        return animation
      } else {
        let animation = 'animated fadeIn faster'
        return animation
      }
    },
    customAnimationExit () {
      if (this.nextPage === 'last') {
        let animation = 'animated fadeOutRight faster'
        return animation
      } else if (this.nextPage === 'next') {
        let animation = 'animated fadeOutLeft faster'
        return animation
      } else {
        let animation = 'animated fadeOut faster'
        return animation
      }
    }
  },
  props: {
    isActive: { required: true, type: Boolean, default: false }
  },
  apollo: {
    graph: {
      query: require('@/graphql/queries/bot/graph.gql'),
      client: 'plus',
      variables () {
        return {
          id: '11f25440-7641-45c0-a850-d770b45e0305'
        }
      },
      result (data) {
        if (data.networkStatus === 7) {
          this.$store.commit('Chatbot/setCurrentGraph', data.data.graph)
          if (!this.$store.state.Chatbot.nodeSelect) {
            this.$store.commit('Chatbot/setNodeSelect', data.data.graph.root.id)
            this.$store.commit('Chatbot/addNavigation', { name: '', id: data.data.graph.root.id })
          }
        }
      },
      fetchPolicy: 'network-only'
    },
    currentNode: {
      query: require('@/graphql/queries/bot/node.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.currentSelectedNode
        }
      },
      update (data) {
        return data.node
      },
      skip () {
        return !this.currentSelectedNode
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    showConseilsList () {
      this.$store.commit('Chatbot/cleanNav')
      this.$store.commit('Chatbot/setBaseNodeSelect', null)
      this.$store.commit('Chatbot/setNodeSelect', this.graph.root.id)
    },
    exitDrawer () {
      this.$emit('exitConseils')
      this.resetQuestion()
    },
    resetQuestion () {
      this.$store.commit('Chatbot/setNodeSelect', this.graph.root.id)
      this.$store.commit('Chatbot/cleanNav')
    },
    goPrec () {
      this.nextPage = 'last'
      const newid = this.histo[ this.histo.length - 2 ]
      if (newid) {
        this.$store.commit('Chatbot/setNodeSelect', newid.id)
        this.$store.commit('Chatbot/removeNavigation', this.histo[this.histo.length - 1])
      } else {
        this.$store.commit('Chatbot/setNodeSelect', this.graph.root.id)
        this.$store.commit('Chatbot/cleanNav')
      }
    },
    goNode (out, direction) {
      this.nextPage = 'next'
      if (direction === 'outgoing-parent') {
        this.$store.commit('Chatbot/setBaseNodeSelect', out)
        this.$store.commit('Chatbot/setNodeSelect', out.target.id)
        this.$store.commit('Chatbot/addNavigation', { name: out.target.titre, id: out.target.id })
      } else {
        this.$store.commit('Chatbot/addNavigation', { name: out.target.titre, id: out.target.id })
        this.$store.commit('Chatbot/setNodeSelect', out.target.id)
      }
    },
    goBackTo (elem) {
      const temp = this.histo.findIndex(el => el.id === elem.id)
      this.$store.commit('Chatbot/removeNavigation', this.histo[temp])
      this.$store.commit('Chatbot/setNodeSelect', this.histo[temp - 1].id)
    }
  }
}
</script>

<template lang="pug">
  .chat-navigation-index.elevation-4()
    div.dialog-button
      v-btn(@click='exitDrawer', fab, x-large, depressed, color='light', :small='mobileTemplate')
        font-awesome-icon.f3.secondary--text(:icon="['fas', 'times']")
    div.dialog-content(style='background-color: white;')
      template(v-if='currentNode')
        template(v-if="currentNode.titre !== 'neutre'")
          template(v-if='baseSelectedNode && baseSelectedNode.target')
            transition(name='custom-classes-transition', enter-active-class='animated fadeIn fast', leave-active-class='animated fadeOut fast', mode='out-in')
              .node-title.animated.fadeIn(v-if='baseSelectedNode && baseSelectedNode.target && histo && histo.length > 1') {{baseSelectedNode.target.titre}}
              .node-title.animated.fadeIn(v-else) Conseils pratiques

            template(v-if='currentNode.data && currentNode.data.ids')
              transition(name='custom-classes-transition', :enter-active-class='customAnimationEnter', :leave-active-class='customAnimationExit', mode='out-in')
                info-node.answer__wrapper(v-for='(id, index) in currentNode.data.ids', :id='id', :type='currentNode.data.kind', :key='id', :title='currentNode.titre')
          template(v-else)
            .node-title Sélectionne un conseil!
            .answer__wrapper
              .conseils__wrapper
                template(v-for='(out, index) in currentNode.outgoing_edges')
                  v-hover(v-slot:default="{ hover }")
                    div.animated(:class='{"jello fast" : hover}')
                      a.conseil(@click="goNode(out, 'outgoing-parent')") {{out.target.titre}}
        template(v-else)
          div.answer__wrapper--empty(v-if='mobileTemplate')
            img.answer-image(src="@/assets/images/logos/dokoma-icon.svg")
            p Construis une phrase, n'aies pas peur!
        template(v-if='histo.length > 1 && baseSelectedNode && baseSelectedNode.target')
          div.relative.w-100
            v-btn.animated.fadeInUp(@click="goPrec", color='light', block, large)
              v-icon mdi-undo
              span.ml2.f5 Étape précédente
        div.question-builder__toolbar
          template(v-if='baseSelectedNode && baseSelectedNode.target')
            template(v-if='currentNode.outgoing_edges.length > 0', v-for='(out, index) in currentNode.outgoing_edges')
              //- span.choice-button(@click="goNode(out, 'outgoing')", :key='index') {{out.target.titre}}
              a.choice-button.w-100.h-100.tc.flex.justify-center.items-center(@click="goNode(out, 'outgoing')", :key='index')
                span Étape suivante

            template(v-if='currentNode.outgoing_edges.length < 1')
              a.choice-button.reset.w-50.h-100.tc.flex.justify-center.items-center(@click='showConseilsList')
                span Voir un autre conseil
              a.choice-button.reset.w-50.h-100.tc.flex.justify-center.items-center(@click='exitDrawer')
                span Fermer les conseils
          template(v-else)
            span.choice-button.reset(@click='exitDrawer') Fermer les conseils
</template>

<style lang='sass'>
  @import 'src/styles/components/chat-bot/_chat-bot-navigation'

</style>

<script>
// @ is an alias to /src
import TextPattern from '@/components/shared/textPattern.vue'
import EnfantsWidget from '@/components/widgets/enfantsWidget.vue'
import EvaluationWidget from '@/components/widgets/evaluationWidget.vue'
import BoutiqueWidget from '@/components/widgets/boutiqueWidget.vue'
import BiblioWidget from '@/components/widgets/biblioWidget.vue'
import ConseilsWidget from '@/components/widgets/conseilsWidget.vue'
import CalendarWidget from '@/components/widgets/calendarWidget.vue'
import MotDuJourWidget from '@/components/widgets/motDuJourWidget.vue'
import ChatBot from '@/components/home/chatBot.vue'
import MeteoMiniWidget from '@/components/widgets/meteoMiniWidget.vue'
import LiensRapides from '@/components/home/liensRapides.vue'
import ColorMenu from '@/components/home/options/colorMenu.vue'
import NightMode from '@/components/home/options/nightMode.vue'
// import SettingsMenu from '@/components/home/options/settingsMenu.vue'
export default {
  name: 'home',
  components: {
    TextPattern,
    EnfantsWidget,
    EvaluationWidget,
    BoutiqueWidget,
    BiblioWidget,
    ConseilsWidget,
    CalendarWidget,
    MotDuJourWidget,
    ChatBot,
    MeteoMiniWidget,
    LiensRapides,
    ColorMenu,
    NightMode
    // SettingsMenu
  },
  data () {
    return {
      conseilsBoard: false,
      conseilsBoardContent: false,
      ajoutAlerte: false
    }
  },
  computed: {
    pendingRequestsDialog: {
      get () { return this.$store.state.Notifications.pendingRequestsDialog },
      set (val) { this.$store.commit('Notifications/setPendingRequestsDialog', val) }
    }
  },
  watch: {
    '$store.state.User.relationEnAttente': {
      immediate: true,
      deep: true,
      handler (newVal) {
        if (newVal.length > 0) {
          this.ajoutAlerte = true
        } else {
          this.ajoutAlerte = false
        }
      }
    }
  },
  methods: {
    showConseilsBoard () {
      if (this.conseilsBoard) {
        this.conseilsBoardContent = false
        this.lockedScroll = false
        setTimeout(() => {
          this.conseilsBoard = false
        }, 500)
      } else {
        this.conseilsBoard = true
        this.lockedScroll = true
        setTimeout(() => {
          this.conseilsBoardContent = true
        }, 500)
        setTimeout(() => {
          this.calculateSizes()
        }, 500)
      }
    },
    calculateSizes () {
      if (this.$refs.drawingBoardContainer) {
        this.draw.height = this.$refs.drawingBoardContainer.clientHeight
        this.draw.width = this.$refs.drawingBoardContainer.clientWidth
      }
    }
  }
}
</script>

<template lang='pug'>
  .home-index
    transition(name='custom-classes-transition', enter-active-class='animated faster fadeIn', leave-active-class='animated faster fadeOut', mode='out-in')
      div.conseils-board__wrapper(v-if='conseilsBoard', :key='conseilsBoard')
        //- pre {{conseilsBoard}} {{conseilsBoardContent}}
        transition(name='custom-classes-transition', enter-active-class='animated fast bounceInUp', leave-active-class='animated fast bounceOutDown', mode='out-in')
          div.conseils-board__content(v-if='conseilsBoardContent', :key='conseilsBoardContent', ref='conseilsBoardContainer')
            chat-bot(:isActive='conseilsBoardContent', @exitConseils='showConseilsBoard')
    v-alert.notification-alert(type='success', dismissible, v-model='ajoutAlerte', transition="scale-transition")
      div.flex.justify-between.items-center
        span Vous avez une demande d'ajout en attente
        div.flex-grow-1
        v-btn.dark--text(@click='pendingRequestsDialog = true', rounded, color='white') Voir la demande
    .index__top-section()
      meteo-mini-widget
      div.flex-grow-1
      //- .fun-options__wrapper
      div(id='s-step-2', style='display:flex; position: relative;')
        night-mode
        color-menu
      //- settings-menu
    .introduction-banner(v-if='$store.state.App.mobileTemplate')
      .intro-header
        h1.intro-title() Bienvenue <br>dans l'espace parent,
        div.intro-subtitle()
          router-link.no-underline(to='/profil', exact, exact-active-class='active-link')
            //- pre {{$store.state.User.profile}}
            v-avatar.white.mr3(size='50',v-if='$store.state.User.profile && $store.state.User.profile.avatar')
              img(v-if='$store.state.User.profile && $store.state.User.profile.avatar && $store.state.User.profile.avatar.imageUrl', :src='$store.state.User.profile.avatar.imageUrl')
              div.f5.secondary--text(v-else-if='$store.state.User.profile && $store.state.User.profile && $store.state.User.profile.info && $store.state.User.profile.info.nom && $store.state.User.profile.info.prenom') {{$store.state.User.profile.info.prenom[0]}}.{{$store.state.User.profile.info.nom[0]}}.
              img(v-else, src='~@/assets/avatars/avatar_afro.png')
            span(v-if='$store.state.User.profile') {{$store.state.User.profile.info.prenom}}
      liens-rapides(v-if='$store.state.App.mobileTemplate')
    calendar-widget(v-if='!$store.state.App.mobileTemplate', id='s-step-3')

    .main-content__wrapper(id='s-step-4')
      .half-card__large
        div.flex.items-center.mb2
          div.widget-title(style='margin-bottom: 0') Mes enfants
          div.flex-grow-1
          //- v-btn(rounded, outlined, small)
            | Créer
            font-awesome-icon.f6.ml2(:icon="['far', 'plus']")
        enfants-widget
      .small-cards__wrapper
        //- .half-card__small
          evaluation-widget
        .half-card__small
          div.widget-title Mot du jour
          mot-du-jour-widget
        .half-card__small
          div.widget-title Foire aux questions
          conseils-widget(@toggleConseilsBoard='showConseilsBoard()')
        .half-card__small
          div.widget-title Documents favoris
          biblio-widget
        .half-card__small
          div.widget-title Pour vos enfants
          //- boutique-widget
          div.widget
            v-sheet.flex.items-center.flex-column.justify-center.relative.pa4(height='300', style='border-radius: 10px; overflow: hidden;')
              text-pattern(data='À venir', :color='"var(--v-secondary-base)"', :size='50', :opacity='0.05', :quantity='30')

              span.tc.relative.b De nouvelles fonctionnalités s'ajouteront graduellement à votre espace parent.
</template>
<style lang='sass'>
@import 'src/styles/pages/_home'
@import 'src/styles/components/widgets/_widgetBase'

</style>

<script>
// import ColorThief from 'color-thief'
import formatPrice from '@/mixins/formatPrice.js'

export default {
  name: 'BoutiqueWidget',
  mixins: [formatPrice],
  components: {
  },
  props: {
    // words: { type: Array, required: true },
  },
  data () {
    return {
      offres: null
    }
  },
  apollo: {
    // offres: {
    //   query: require('@/graphql/queries/boutique/recherche.gql'),
    //   client: 'boutique',
    //   variables () {
    //     return {
    //       filtre: {
    //         attributs: [{
    //           niveauxZonages: ['36'] }, {
    //           matieres: ['11']
    //         }],
    //         types: ['LIVRE'] // dans un premier temps on filtre sur les livres
    //       }
    //     }
    //   },
    //   update (data) {
    //     // console.log(data.recherche)
    //     return data.recherche
    //   }
    // }
  },
  computed: {
  },
  watch: {
  },
  beforeDestroy () {
  },
  mounted () {
    // this.$nextTick(() => {
    //   const colorThief = new ColorThief()
    //   const palette = colorThief.getPalette(this.$refs.myImg)
    //   /* do something with `palette` RGB array */
    //   console.log('palette', palette)
    // })
  },
  methods: {
    goTo (slug) {
      window.open('https://www.grandducenligne.com/fr/livres/' + slug, '_blank')
    },
    getFontSize (mot) {
      // console.log(mot.length)
      if (mot.length <= 10) {
        return 3 + 'em'
      } else if (mot.length > 10 && mot.length <= 20) {
        return 2 + 'em'
      } else if (mot.length > 20 && mot.length <= 40) {
        return 2 + 'em'
      } else if (mot.length > 30 && mot.length <= 50) {
        return 1.5 + 'em'
      } else {
        return 1 + 'em'
      }
    }
  }
}
</script>

<template lang='pug'>
.boutique-widget-index.widget
  //- .widget__secondary-title Produits en vedette
  v-carousel(hide-delimiters, show-arrows-on-hover, height='300',v-if='offres && offres.records')
    v-carousel-item(v-for='(produit, index) in offres.records', :key='index')
      v-sheet.black--text(color='white', height='100%', tile)
        .produit-card
          .produit-preview
            img(v-if='produit.couverture', :src='produit.couverture.url')
          .produit-infos
            .produit-reference
              span Suggestion pour
              v-spacer
              div.eleve
                v-list-item-avatar(size='20', color='primary')
                  img(src='~@/assets/avatars/avatar_dodo.png')
                span.i.b Bobby Junior
            v-spacer
            .produit-title(:style="{ fontSize: getFontSize(produit.titre)}") {{produit.titre}}
            div.produit-infos-secondaires
              span.b {{produit.niveauxPrincipales[0].niveauZonage.titre}}
              span.grey--text(v-if='produit.matieresPrincipales && produit.matieresPrincipales.length > 0') {{produit.matieresPrincipales[0].titre}}
            //- div.i.mt2(v-html='produit.resume')
            div.actions__wrapper(style='display:flex; justify-content:space-between; align-items:center;')
              div.produit-price
                span(v-if='produit.formats && produit.formats.length > 0 && produit.formats[0].prix.final') {{formatPrice(produit.formats[0].prix.final)}}
                span.f5.i(v-else) Prix non dispo
              v-btn.b(depressed, rounded, @click='goTo(produit.slug)', color='black', dark, small) Voir le produit

              //- v-btn.mt3(rounded, outlined, ) Voir le produit
  v-btn.ttc.widget-extras__button.white--text(block, depressed, color='black', href='https://www.grandducenligne.com/fr', target='_blank') Explorer la boutique
</template>
<style lang='sass'>
@import 'src/styles/components/widgets/_productsCards'

</style>

<script>
export default {
  name: 'MotduJourWidget',
  data () {
    return {
      activeDefinition: false,
      activeDefinitionDialog: false,
      carousel: 0,
      motDuJour: null,
      loading: false
    }
  },
  computed: {
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    }
  },
  watch: {
    mobileTemplate (newVal) {
      if (newVal === false) {
        this.activeDefinitionDialog = false
      }
    }
  },
  apollo: {
    motDuJour: {
      query: require('@/graphql/queries/plus/motDuJour.gql'),
      client: 'plus',
      update (data) {
        // Y A DES SYNONYMES DE DISPOS AUSSI SI TU VEUX LES AFFICHER
        return data.mot_du_jour
      },
      loadingKey: 'loading',
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    getFontSize (mot) {
      if (!this.mobileTemplate) {
        if (mot.length <= 8) {
          return 3 + 'em'
        } else if (mot.length > 8 && mot.length <= 15) {
          return 2 + 'em'
        } else if (mot.length > 15 && mot.length <= 20) {
          return 1.75 + 'em'
        } else if (mot.length > 20 && mot.length <= 30) {
          return 1.5 + 'em'
        } else {
          return 1.25 + 'em'
        }
      } else {
        if (mot.length <= 10) {
          return 2.25 + 'em'
        } else if (mot.length > 10 && mot.length < 15) {
          return 1.75 + 'em'
        } else if (mot.length >= 15 && mot.length <= 20) {
          return 1.5 + 'em'
        } else if (mot.length > 20 && mot.length <= 30) {
          return 1.25 + 'em'
        } else {
          return 1.15 + 'em'
        }
      }
    }
  }
}
</script>

<template lang='pug'>
.motdujour-widget-index.widget

  template(v-if='loading')
    v-sheet(color='secondary', height='100%', tile, dark)
      .mot-card__wrapper.animated.fadeIn
        div.mot-card()
          .mot-presentation()
            div() Mot du jour
          v-spacer
          .mot-du-jour(:style="{ fontSize: getFontSize('Chargement')}") Chargement
          v-spacer
          .mot-definition-count() Nous cherchons pour un mot incroyable
  template(v-else-if='motDuJour')
    v-dialog(v-model='activeDefinitionDialog', content-class='active-definition-dialog custom-dialog', max-width='600')
      v-card.relative.white.secondary--text
        v-hover(v-slot:default="{ hover }")
          v-btn.dialog-button(@click='activeDefinitionDialog = false', depressed, fab, x-large, color='primary')
            font-awesome-icon.f3.secondary--text(:icon="['fas', 'times']")
        v-card-title.dialog-title.flex-column.secondary.white--text
          div.f5 Mot du jour
          h2.mot-title(v-if='motDuJour.titre') {{motDuJour.titre}}
          span.tc(v-if='motDuJour.definitions && motDuJour.definitions.length') {{motDuJour.definitions.length}} définition{{motDuJour.definitions.length > 1 ? 's' : ''}}
        v-card-text.dialog-text(style='padding: 0 !important')
          v-carousel.dialog-description(height='400', hide-delimiters, :showArrows='(motDuJour && motDuJour.definitions && motDuJour.definitions.length > 1)', show-arrows-on-hover, v-model='carousel')
            v-carousel-item(v-for='(definition, index) in motDuJour.definitions')
              v-sheet.secondary--text(color='white', height='300', tile)
                .mot-definition__wrapper
                  perfect-scrollbar.mot-definition(:options='{wheelPropagation: false}')
                    div.f4(v-html='definition.titre')
                    div.f6.i.mt3(v-html='definition.exemples')

    v-sheet(color='secondary', height='100%', tile, dark)
      .mot-card__wrapper.animated.fadeIn
        div.mot-card(@mouseover='!mobileTemplate ? activeDefinition = true : activeDefinition = false', @mouseleave='activeDefinition = false')
          transition(name='custom-classes-transition', enter-active-class='animated faster fadeIn', leave-active-class='animated faster fadeOut', mode='out-in')
            .mot-presentation(:key='activeDefinition && !mobileTemplate')
              div(v-if='!activeDefinition && motDuJour.titre') Mot du jour
              div(v-else-if='activeDefinition && motDuJour.titre') {{motDuJour.titre}}
          v-spacer
          .mot-du-jour(v-if='motDuJour.titre', :style="{ fontSize: getFontSize(motDuJour.titre)}") {{motDuJour.titre}}
          v-spacer
          template(v-if='!mobileTemplate && motDuJour.definitions && motDuJour.definitions.length')
            transition(name='custom-classes-transition', enter-active-class='animated faster fadeIn', leave-active-class='animated faster fadeOut', mode='out-in')
              .mot-definition-count(:key='activeDefinition')
                div(v-if='activeDefinition') {{carousel + 1}} / {{motDuJour.definitions.length}}
                div(v-else) {{motDuJour.definitions.length}} définition{{motDuJour.definitions.length > 1 ? 's' : ''}}
          v-btn.description-button(v-else, rounded, outlined, dark, style='pointer-events:auto', @click='activeDefinitionDialog = !activeDefinitionDialog') Voir {{motDuJour.definitions.length > 1 ? 'les ' + motDuJour.definitions.length + ' définitions' : 'la définition'}}

          template(v-if='!mobileTemplate && motDuJour.definitions && motDuJour.definitions.length')
            v-carousel.mot-card__extra(light, hide-delimiters, :showArrows='(motDuJour && motDuJour.definitions && motDuJour.definitions.length > 1)', show-arrows-on-hover, v-model='carousel')
              v-carousel-item(v-for='(definition, index) in motDuJour.definitions')
                v-sheet(color='transparent', height='100%', tile)
                  .mot-definition__wrapper
                    perfect-scrollbar.mot-definition(:options='{wheelPropagation: false}')
                      div(v-html='definition.titre')
                      div.f6.i.mt2(v-html='definition.exemples')
  //- EN CAS D'ERREUR
  template(v-else)
    v-dialog(v-model='activeDefinitionDialog', content-class='active-definition-dialog custom-dialog', max-width='600')
      v-card.relative
        v-hover(v-slot:default="{ hover }")
          v-btn.dialog-button(@click='activeDefinitionDialog = false', depressed, fab, x-large, color='primary')
            font-awesome-icon.f3(:icon="['fas', 'times']")
        v-card-title.dialog-title.flex-column
          div.f5 Mot du jour
          h2.mot-title Erreur
          span.tc() 1 définition
        v-card-text.dialog-text(style='padding: 0 !important')
          v-carousel.dialog-description(height='400', hide-delimiters, v-model='carousel')
            v-carousel-item()
              v-sheet(color='secondary', height='400', tile)
                .mot-definition__wrapper
                  perfect-scrollbar.mot-definition(:options='{wheelPropagation: false}')
                    div() En informatique: Dysfonctionnement (matériel ou logiciel) survenant sur un ordinateur.
                    div.f6.i.mt2() Ah non! Il semble y avoir une erreur avec le mot du jour!
    v-sheet(color='secondary', height='100%', tile, dark)
      .mot-card__wrapper.animated.fadeIn
        div.mot-card()
          transition(name='custom-classes-transition', enter-active-class='animated faster fadeIn', leave-active-class='animated faster fadeOut', mode='out-in')
            .mot-presentation(:key='activeDefinition && !mobileTemplate')
              div(v-if='!activeDefinition') Mot du jour
              div(v-else-if='activeDefinition') Erreur
          v-spacer
          .mot-du-jour(:style="{ fontSize: getFontSize('erreur')}") Erreur
          v-spacer
          template(v-if='!mobileTemplate')
            transition(name='custom-classes-transition', enter-active-class='animated faster fadeIn', leave-active-class='animated faster fadeOut', mode='out-in')
              .mot-definition-count(:key='activeDefinition')
                div() 1 Définition
          v-btn.description-button(v-else, rounded, outlined, dark, style='pointer-events:auto', @click='activeDefinitionDialog = !activeDefinitionDialog') Voir la définition
          template(v-if='!mobileTemplate')
            v-carousel.mot-card__extra(hide-delimiters, :showArrows='false', v-model='carousel')
              v-carousel-item()
                v-sheet(color='transparent', height='100%', tile)
                  .mot-definition__wrapper
                    perfect-scrollbar.mot-definition(:options='{wheelPropagation: false}')
                      div() En informatique: Dysfonctionnement (matériel ou logiciel) survenant sur un ordinateur.
                      div.f6.i.mt2() Ah non! Il semble y avoir une erreur avec le mot du jour!
</template>
<style lang='sass'>
  @import 'src/styles/components/widgets/_motDuJour'
  .active-definition-dialog
    .v-card
      // min-height: 600px
    .mot-title
      font-family: $display-font
      font-size: 2em
      line-height: 1.15
      &::first-letter
        text-transform: uppercase
    .mot-title ~ span
      font-family: $body-font-family
      font-size: 14px
    .dialog-description
      width: 100%
      height: 100% !important
      overflow-y: auto
      color: var(--v-dark-base)
      .v-carousel__item
        // min-height: 400px
        height: 100% !important
        width: 100%
        .mot-definition__wrapper
          width: 100%
          padding: 10px
          height: 100%
          display: flex
          flex-direction: column
          text-align: center
          padding: 20px
          overflow-y: auto
          .conseil-card__thematique
            font-size: 0.95em
            font-style: italic
            padding-bottom: 10px
            opacity: 0.75
          .mot-definition
            margin: auto
            padding: 30px 0px
            font-size: 1em
            line-height: 1.15
            span, span *
              color: var(--v-light-base)
              text-decoration: none
              pointer-events: none
            .mot_parent
              list-style: none
            .mot
              display: none
            .classes, .classes *
              text-decoration: none
              pointer-events: none
    @media all and (max-width: $small)
      .mot-title
        font-size: 1.5em

    .mot-definition
      margin: auto
      // padding: 30px 0px
      font-size: 1em
      line-height: 1.15
      span, span *
        color: var(--v-secondary-base)
        text-decoration: none
        pointer-events: none
      .mot_parent
        list-style: none
      .mot
        display: none
      .classes, .classes *
        text-decoration: none
        pointer-events: none
</style>

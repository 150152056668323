<script>
import ChatControls from '@/mixins/chatControls.js'
// import draggable from 'vuedraggable'
const { detect } = require('detect-browser')
export default {
  name: 'liensRapides',
  components: {
    // draggable,
    // AjoutRelation
  },
  mixins: [ChatControls],
  data () {
    return {
      dialogCode: false,
      dialogResource: false,
      source: '',
      browserDetect: null,
      disabledClick: false
    }
  },
  computed: {
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    },
    showAjoutCode: {
      set (val) { this.$store.commit('Dialogs/setAjoutCode', val) },
      get () { return this.$store.state.Dialogs.showAjoutCode }
    }
  },
  created () {
    this.browserDetect = detect()
  },
  methods: {
    showDialogResource (source) {
      this.source = source
      this.dialogResource = !this.dialogResource
    }
  }
}
</script>

<template lang='pug'>
.quick-access
  perfect-scrollbar.quick-access__scroller(v-dragscroll='mobileTemplate', @dragscrollstart="disabledClick = true", @dragscrollend="disabledClick = false", :class='{"disable-click" : (disabledClick && mobileTemplate)}')
    .quick-access__wrapper(@start='drag=true', @end='drag=false', :disabled='true')

        div.quick-link()
          router-link.no-underline(to='/profil')
            div.quick-link-button
              font-awesome-icon.side-icon(:icon="['fad', 'user']", :class='{"f2" : mobileTemplate}')
            .quick-link-title__mobile Mon profil

        div.quick-link
          a.no-underline(@click='dialogCode = !dialogCode')
            div.quick-link-button
              //- font-awesome-icon.f4.drag-icon(v-if='!mobileTemplate', :icon="['fas', 'grip-vertical']")
              font-awesome-icon.side-icon(:icon="['fad', 'users-medical']", :class='{"f2" : mobileTemplate}')
            .quick-link-title__mobile Créer un compte <br> enfant
        div.quick-link()
          router-link.no-underline(to='/enfants')
            div.quick-link-button
              font-awesome-icon.side-icon(:icon="['fad', 'apple-alt']", :class='{"f2" : mobileTemplate}')
            .quick-link-title__mobile Espace enfants

        div.quick-link()
          router-link.no-underline(to='/calendrier')
            div.quick-link-button
              font-awesome-icon.side-icon(:icon="['fad', 'calendar-alt']", :class='{"f2" : mobileTemplate}')
            .quick-link-title__mobile Calendrier

        div.quick-link()
          a.no-underline(@click='goChat()')
            div.quick-link-button
              v-badge.vuetify-badge(v-model='$store.state.App.newMess && mobileTemplate', color='red', icon='mdi-exclamation-thick', overlap)
                font-awesome-icon.side-icon(:icon="['fad', 'comment-smile']", :class='{"f2" : mobileTemplate}')
            .quick-link-title__mobile Discuter
</template>
<style lang='sass'>
  @import 'src/styles/components/widgets/_quickAccessHome'
</style>

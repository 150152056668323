<script>
import EnfantCard from '@/components/presentation/enfantCard.vue'

export default {
  name: 'EnfantsWidget',
  components: {
    EnfantCard
  },
  data () {
    return {
    }
  },
  created () {
    if (this.$store.state.User.relations.length === 0) {
      this.$store.dispatch('User/fetchEnfants')
    }
  },
  watch: {
    '$store.state.User.refetchEnfant' (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$store.dispatch('User/fetchEnfants')
      }
    }
  },
  computed: {
    showAjoutEnfant: {
      set (val) { this.$store.commit('Dialogs/setAjoutEnfant', val) },
      get () { return this.$store.state.Dialogs.showAjoutEnfant }
    },
    relations () {
      return this.$store.state.User.relations
    }
  }
}
</script>

<template lang='pug'>
.enfants-widget-index.widget
  v-list.light-list.main-menu__list(v-if='$store.state.User.enfantsLoading')
    template
      v-skeleton-loader.w-100(type="list-item-avatar-three-line, article, actions")
  v-list.main-menu__list.animated.fadeIn(v-else-if='!$store.state.User.enfantsLoading && relations && relations.length > 0', color='transparent', rounded)
    enfant-card(v-for='(user, index) in relations', :key='index', :user='user.inferieur', :id='user.inferieur.v1Id', :accueil='true')
    div.pa3.light
      v-btn(block,rounded, large, :color='$store.state.Preferences.darkMode ? "secondary" : "primary"', @click='showAjoutEnfant = !showAjoutEnfant', outlined) Créer un nouveau compte enfant

  v-list.light-list.main-menu__list.animated.fadeIn(v-else)
    div.pa4(style='line-height: 1.15')
      div.b Aucun compte élève n'est lié à votre compte.
      div.f6.mt3 Vos enfant n'ont pas de compte? Vous pouvez leur créer un compte par ici
      v-btn.mt3(block, rounded, large, color='primary', @click='showAjoutEnfant = !showAjoutEnfant', outlined) Créer un compte enfant
</template>
<style lang='sass'>
</style>

<script>
export default {
  name: 'ConseilsWidget',
  // mixins: [lottieMixin, calculateFontSize],
  components: {
  },
  props: {
    // words: { type: Array, required: true },
  },
  data () {
    return {
    }
  },
  computed: {
    mobileTemplate () {
      return this.$store.state.App.mobileTemplate
    }
  },
  watch: {
  },
  beforeDestroy () {
  },
  mounted () {
  },
  apollo: {
    graph: {
      query: require('@/graphql/queries/bot/graph.gql'),
      client: 'plus',
      variables () {
        return {
          id: '11f25440-7641-45c0-a850-d770b45e0305'
        }
      },
      result (data) {
        return data.data.graph
      },
      fetchPolicy: 'network-only'
    },
    currentNode: {
      query: require('@/graphql/queries/bot/node.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.graph.root.id
        }
      },
      update (data) {
        return data.node
      },
      skip () {
        return !this.graph
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    launchChatBot (noeud) {
      this.$store.commit('Chatbot/setBaseNodeSelect', noeud)
      this.$store.commit('Chatbot/setNodeSelect', noeud.target_id)
      this.$store.commit('Chatbot/addNavigation', { name: noeud.target.titre, id: noeud.target_id })
      this.$emit('toggleConseilsBoard')
    },
    getFontSize (mot) {
      if (mot.length <= 20) {
        return 1.85 + 'em'
      } else if (mot.length > 20 && mot.length <= 30) {
        return 1.65 + 'em'
      } else if (mot.length > 30 && mot.length <= 50) {
        return 1.35 + 'em'
      } else if (mot.length > 50 && mot.length <= 70) {
        return 1.15 + 'em'
      } else {
        return 1 + 'em'
      }
    }
  }
}
</script>

<template lang='pug'>
.conseil-widget-index.widget(v-if='graph && graph.nodes')
  v-carousel(hide-delimiters, show-arrows-on-hover, cycle, v-if='currentNode', height='300')
    template(v-for='(noeud, index) in currentNode.outgoing_edges')
      v-carousel-item(:key='index')
        v-sheet(color='primary', height='100%', tile)
          .conseil-card(v-if='noeud.target')
            .conseil-card__thematique Aide aux devoirs
            .conseil-card__title(v-if='noeud.target.titre', :style="{ fontSize: getFontSize(noeud.target.titre)}") {{noeud.target.titre}}
            v-btn.conseil-card__button(rounded, outlined, @click.native='launchChatBot(noeud)') Me renseigner
</template>
<style lang='sass'>
  @import 'src/styles/components/widgets/_conseilsCards'

</style>
